<template>
  <CsTableCard
    :columns="columns"
    :loading="loading"
    :rows="rows"
    card-header="Elenco Utenti"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <cs-table-action-column-default
        :show-actions="canDetails || canRemove"
        :props="props"
      >
        <cs-table-action-item
          v-if="canDetails"
          :row="props.row"
          icon="BookOpenIcon"
          value="Permessi"
          @click="permessiRow"
        />

        <cs-table-action-item
          v-if="canDetails"
          :row="props.row"
          icon="BookOpenIcon"
          value="Ruoli"
          @click="ruoliRow"
        />

        <cs-table-action-item-delete
          v-if="canRemove"
          :row="props.row"
          @click="removeRow"
        />
      </cs-table-action-column-default>

    </template>

  </CsTableCard>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      columns: [
        {
          label: 'Nominativo',
          field: 'nominativo',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca...',
          },
        },
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      canRemove: this.$grants.UTENTI_ELIMINA.can,
      canDetails: this.$grants.UTENTI_VISUALIZZA_DETTAGLIO.can,
    }
  },
  created() {
    this.$remote.utenti.all()
      .then(result => { this.rows = result.data })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })
  },
  methods: {
    removeRow(params) {
      this.showDeleteSwal().then(res => {
        if (!res.value) return
        this.loading = true
        this.$remote.utenti.delete(params.id)
          .then(() => {
            this.deleteRow(this.rows, params.originalIndex)
            this.showDeleteSuccessMessage()
          })
          .catch(err => this.showReposonseMessage(err))
          .finally(() => { this.loading = false })
      })
    },
    permessiRow(params) {
      this.$routing.UTENTI_PERMESSI.push({ id: params.id })
    },
    ruoliRow(params) {
      this.$routing.UTENTI_RUOLI.push({ id: params.id })
    },
  },
}
</script>
